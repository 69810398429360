












































































































































































































































































































import Vue from 'vue';

export default Vue.extend({
  name: 'PlatformPlansSection',
  data() {
    return {
      planLink:
        'https://pgt.professorgabrielcabral.com.br/checkout?plan=rVdEyodp',
    };
  },
  methods: {
    openRegistrationForm(link: string) {
      window.location.href = link;
    },
  },
});
