




























import { Vue } from 'vue-property-decorator';
import PlatformPublicSection from '@/components/sections/PlatformPublicSection.vue';
import PlatformVideoSection from '@/components/sections/PlatformVideoSection.vue';
import MotivationMessageSection from '@/components/sections/MotivationMessageSection.vue';
import PlatformBenefitsSection from '@/components/sections/PlatformBenefitsSection.vue';
import PlatformPlansSection from '@/components/sections/PlatformPlansSection.vue';
import MoneyBackSection from '@/components/sections/MoneyBackSection.vue';
import PlatformTestimonialSection from '@/components/sections/PlatformTestimonialSection.vue';
import PlatformTeacherSection from '@/components/sections/PlatformTeacherSection.vue';
import PlatformFaqSection from '@/components/sections/PlatformFaqSection.vue';
import AppHeaderSectionBg from '@/components/AppHeaderSectionBg.vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'Home',
  components: {
    PlatformPublicSection,
    PlatformVideoSection,
    MotivationMessageSection,
    PlatformBenefitsSection,
    PlatformPlansSection,
    MoneyBackSection,
    PlatformTestimonialSection,
    PlatformTeacherSection,
    PlatformFaqSection,
    AppHeaderSectionBg,
  },
  computed: mapGetters(['displayRegistrationList']),
  created() {
    this.$store.dispatch('updateHeaderBgColor', 'transparent');
    this.$store.dispatch('updateHeaderAsApp', true);
  },
});
