/* eslint-disable no-param-reassign */
import { ActionTree, GetterTree } from 'vuex';
import State from '../types/State';
import { AppState } from '../types/AppState';

const initialState = {
  loadingRequests: 0,
  drawerOpen: false,
  headerAsApp: true,
  headerBgColor: 'transparent',
  showCookieConsent: true,
  showRegistrationList: false,
} as AppState;

const actions: ActionTree<AppState, State> = {
  startLoading: ({ commit }): void => {
    commit('ackLoading');
  },
  finishLoading: ({ commit }): void => {
    commit('releaseLoading');
  },
  toggleDrawer: ({ commit }): void => {
    commit('setDrawer');
  },
  updateHeaderBgColor: ({ commit }, params?: string): void => {
    commit('setBgColor', params);
  },
  updateHeaderAsApp: ({ commit }, params: boolean): void => {
    commit('setHeaderAsApp', params);
  },
  updateCookieConsent: ({ commit }, params: boolean): void => {
    commit('setShowCookieConsent', params);
  },
  updateRegistrationList: ({ commit }, params: boolean): void => {
    commit('setShowRegistrationList', params);
  },
};

const mutations = {
  ackLoading: (current: AppState): void => {
    current.loadingRequests += 1;
  },
  releaseLoading: (current: AppState): void => {
    if (current.loadingRequests > 0) {
      current.loadingRequests -= 1;
    }
  },
  setDrawer: (current: AppState): void => {
    current.drawerOpen = !current.drawerOpen;
  },
  setBgColor: (current: AppState, newValue?: string): void => {
    current.headerBgColor = newValue;
  },
  setHeaderAsApp: (current: AppState, newValue: boolean): void => {
    current.headerAsApp = newValue;
  },
  setShowCookieConsent: (current: AppState, newValue: boolean): void => {
    current.showCookieConsent = newValue;
  },
  setShowRegistrationList: (current: AppState, newValue: boolean): void => {
    current.showRegistrationList = newValue;
  },
};

const getters: GetterTree<AppState, State> = {
  appIsLoading: (current) => current.loadingRequests > 0,
  isDrawerOpen: (current) => current.drawerOpen,
  headerAsApp: (current) => current.headerAsApp,
  headerBgColor: (current) => current.headerBgColor,
  showCookieConsent: (current) => current.showCookieConsent,
  displayRegistrationList: (current) => current.showRegistrationList,
};

export default {
  namespace: true,
  state: initialState,
  actions,
  mutations,
  getters,
};
