


















import Vue from 'vue';

export default Vue.extend({
  name: 'MoneyBackSection',
  props: {
    days: {
      type: String,
    },
  },
});
