





















import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'CookieConsent',
  computed: mapGetters(['showCookieConsent']),
  methods: {
    updateOpen(open: boolean) {
      this.$store.dispatch('updateCookieConsent', open);
    },
  },
});
