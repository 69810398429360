import Vue from 'vue';
import Vuex from 'vuex';
import State from './types/State';
import AppVuex from './modules/AppVuex';

Vue.use(Vuex);

export default new Vuex.Store<State>({
  modules: {
    app: AppVuex,
  },
});
