
























import Vue from 'vue';

export default Vue.extend({
  name: 'RegistrationListDialog',
  props: {
    value: {
      default: false,
      type: Boolean,
    },
    url: {
      type: String,
      required: true,
    },
  },
  computed: {
  },
  methods: {
    changeDialogState(open: boolean) {
      this.$store.dispatch('updateRegistrationList', false);
      this.$emit('input', open);
    },
  },
});
