





























import Vue from 'vue';
import faq from '@/metadata/Faq';

export default Vue.extend({
  name: 'PlatformFaqSection',
  data() {
    return {
      open: false,
      faq,
    };
  },
  methods: {
    toggleFaq() {
      this.open = !this.open;
    },
  },
});
