import { IUserTypes } from '@/types/UserTypes';

const menus: IUserTypes[] = [
  {
    icon: 'user-astronaut',
    image: '/img/public/1.webp',
    label: 'platform_public.user_types.1',
  },
  {
    icon: 'user-md',
    image: '/img/public/2.webp',
    label: 'platform_public.user_types.2',
  },
  {
    icon: 'user-tie',
    image: '/img/public/3.webp',
    label: 'platform_public.user_types.3',
  },
  {
    icon: 'user-nurse',
    image: '/img/public/4.webp',
    label: 'platform_public.user_types.4',
  },
];

export default menus;
