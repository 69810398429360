/* eslint-disable no-shadow */
export enum IconType {
  FONTAWESOME = 'FONTAWESOME',
}

export interface Benefit {
  label: string;
  iconType: IconType;
  fontAwesomeIcon: [ string, string ];
}
