import Vue from 'vue';
import Vuetify from 'vuetify';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import 'vuetify/dist/vuetify.min.css';
import i18n from './i18n';

library.add(fas);
library.add(far);
library.add(fab);
Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'faSvg',
  },
  lang: {
    t: (key, ...params) => i18n.t(key, params) as string,
  },
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: '#1854A5',
        secondary: '#E7204E',
        accent: '#ffd800',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
      },
      dark: {
        primary: '#314255',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
      },
    },
  },
});
