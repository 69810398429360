

























import Vue from 'vue';

export default Vue.extend({
  name: 'AppHeaderSectionBg',
  methods: {
    onButtonClick(link: string) {
      window.location.href = link;
    },
  },
});
