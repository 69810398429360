



































import Vue from 'vue';

export default Vue.extend({
  name: 'VideoDialog',
  props: {
    value: {
      default: false,
      type: Boolean,
    },
    url: {
      type: String,
      required: true,
    },
  },
  computed: {
    youtubeUrl() {
      return `${this.url}?enablejsapi=1&version=3&playerapiid=ytplayer`;
    },
    videoWidth() {
      return window.innerWidth < 576 ? '100%' : '600';
    },
  },
  methods: {
    changeDialogState(open: boolean) {
      this.stopVideo();
      this.$emit('input', open);
    },
    stopVideo() {
      const iframe = document.getElementById('player') as HTMLIFrameElement;
      if (iframe && iframe.contentWindow) {
        iframe.contentWindow.postMessage(`{"event":"command","func":"${'stopVideo'}","args":""}`, '*');
      }
    },
  },
});
