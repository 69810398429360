






















import Vue from 'vue';
import userTypes from '@/metadata/UserTypes';

export default Vue.extend({
  name: 'PlatformPublicSection',
  data() {
    return {
      userTypes,
    };
  },
});
