import { Benefit, IconType } from '@/types/Benefit';

const benefits: Benefit[] = [
  {
    label: 'platform_benefits.benefits.study_schedule',
    iconType: IconType.FONTAWESOME,
    fontAwesomeIcon: ['fas', 'calendar-alt'],
  },
  {
    label: 'platform_benefits.benefits.support_material',
    iconType: IconType.FONTAWESOME,
    fontAwesomeIcon: ['fas', 'book-open'],
  },
  {
    label: 'platform_benefits.benefits.classes',
    iconType: IconType.FONTAWESOME,
    fontAwesomeIcon: ['fas', 'chalkboard-teacher'],
  },
  {
    label: 'platform_benefits.benefits.exercises',
    iconType: IconType.FONTAWESOME,
    fontAwesomeIcon: ['fas', 'spell-check'],
  },
  {
    label: 'platform_benefits.benefits.solved',
    iconType: IconType.FONTAWESOME,
    fontAwesomeIcon: ['fas', 'tasks'],
  },
  {
    label: 'platform_benefits.benefits.simulation',
    iconType: IconType.FONTAWESOME,
    fontAwesomeIcon: ['fas', 'sliders-h'],
  },
  {
    label: 'platform_benefits.benefits.test_in_video',
    iconType: IconType.FONTAWESOME,
    fontAwesomeIcon: ['fas', 'video'],
  },
  {
    label: 'platform_benefits.benefits.evaluations',
    iconType: IconType.FONTAWESOME,
    fontAwesomeIcon: ['fas', 'check-double'],
  },
];

export default benefits;
