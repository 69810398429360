























































import Vue from 'vue';
import menus from '@/metadata/Menus';
import { IMenu } from '@/types/Menu';
import { Framework } from 'vuetify';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'AppHeader',
  props: {
    hideOnScroll: {
      type: Boolean,
      default: true,
    },
    help: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menus,
    };
  },
  computed: {
    bgColorStyle() {
      return `background-color: ${this.headerBgColor ?? (this.$vuetify as Framework).theme.currentTheme.primary}; !important`;
    },
    ...mapGetters(['headerAsApp', 'headerBgColor']),
    mailto() {
      return `mailto:${this.$t('mailto')}`;
    },
  },
  methods: {
    toggleDrawer() {
      this.$store.dispatch('toggleDrawer');
    },
    onClick(menu: IMenu) {
      if (menu.link.charAt(0) === '#') {
        const view = document.querySelector('#plans') as HTMLElement;
        if (view) {
          this.$vuetify.goTo(view, { duration: 600 });
        }
      } else {
        document.location = menu.link;
      }
    },
  },
});
