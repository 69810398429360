












import Vue from 'vue';
import { Framework } from 'vuetify';

export default Vue.extend({
  name: 'MotivationMessageSection',
  props: {
    backgroundImage: {
      type: String,
    },
    backgroundColor: {
      type: String,
    },
    messageColor: {
      type: String,
    },
    borderColor: {
      type: String,
    },
    messageLabel: {
      type: String,
    },
    className: String,
  },
  computed: {
    getClasses() {
      return {
        'fullwidth-section': true,
        'motivation-section': true,
        [this.className]: !!this.className,
      };
    },
    messageStyle() {
      return `color: ${this.messageColor ?? (this.$vuetify as Framework).theme.currentTheme.accent};`;
    },
    borderColorStyle() {
      return `border-color: ${this.borderColor ?? (this.$vuetify as Framework).theme.currentTheme.accent};`;
    },
    backgroundStyle() {
      if (this.backgroundImage) {
        console.log(this.backgroundImage);
        return `background-image: url(${this.backgroundImage}); `;
      }
      return `background-color: ${this.backgroundColor ?? (this.$vuetify as Framework).theme.currentTheme.primary};`;
    },
  },
});
