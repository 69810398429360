





























import Vue from 'vue';
import { mapGetters } from 'vuex';
import menus from '@/metadata/Menus';
import { IMenu } from '@/types/Menu';

export default Vue.extend({
  name: 'SideMenu',
  computed: mapGetters(['isDrawerOpen']),
  data() {
    return {
      menus,
    };
  },
  methods: {
    onInputChange(value: boolean) {
      if (!value) {
        this.$store.dispatch('toggleDrawer');
      }
    },
    onClick(menu: IMenu) {
      if (menu.link.charAt(0) === '#') {
        const view = document.querySelector('#plans') as HTMLElement;
        if (view) {
          this.$vuetify.goTo(view, { duration: 600 });
          this.onInputChange(false);
        }
      } else {
        document.location = menu.link;
      }
    },
  },
});
