
































import Vue from 'vue';

export default Vue.extend({
  name: 'PlatformTeacherSection',
  props: {
    teacherName: String,
    youtubeChannel: String,
  },
  methods: {
    onButtonClick() {
      const view = document.querySelector('#plans') as HTMLElement;
      if (view) {
        this.$vuetify.goTo(view, { duration: 600 });
      }
    },
  },
});
