import IFAQ from '@/types/Faq';

const faq: IFAQ[] = [
  {
    question: 'Como funciona o curso?',
    answer: 'O curso possui aulas 100% online, gravadas para que você possa assistir quando e onde quiser. A plataforma conta com plano de aula, apostilas (download), videoaulas, exemplos com resolução em vídeo, experimentos em vídeo, problemas com resolução, simulações interativas, resumos e avaliação.',
    display: true,
  },
  {
    question: 'Fiz o pagamento, e agora?',
    answer: 'Você receberá login e senha por e-mail. Tudo automático. 😎',
    display: true,
  },
  {
    question: 'Posso desistir do curso e ser reembolsado?',
    answer: 'Envie uma mensagem solicitando o cancelamento para o e-mail contato@fisicaensinosuperior.com.br que vamos verificar e realizar conforme as regras estabelecidas em nosso site: Após o pagamento, você terá 7 dias corridos para degustar o curso à vontade. Se você solicitar o cancelamento até o 7º dia, o valor integral será estornado (devolvido sem taxas).',
    display: true,
  },
  {
    question: 'Estou começando do zero funciona para mim?',
    answer: 'Sim, o curso irá te ajudar a aprender Física. Isso porque os conteúdos vão do básico ao avançado, de forma prática e direcionada. Toda a plataforma foi organizada para facilitar seu aprendizado, mesmo que você esteja começando do absoluto zero.',
    display: true,
  },
  {
    question: 'Como posso tirar dúvidas quando assistir às aulas?',
    answer: 'Quando você assistir às aulas, você poderá enviar suas dúvidas através da Monitoria (menu lateral esquerdo), as quais serão respondidas pelo professor, ou um tutor.',
    display: true,
  },
  {
    question: 'Quanto tempo dura o acesso do curso de Física?',
    answer: 'O acesso é válido por 12 meses (365 dias).',
    display: true,
  },
  {
    question: 'Posso baixar as videoaulas?',
    answer: 'Não, todas as aulas só podem ser acessadas dentro da plataforma.',
    display: true,
  },
  {
    question: 'Quais as formas de pagamento?',
    answer: 'As três formas aceitas são por Pix, Boleto Bancário ou Cartão de Crédito, vale lembrar que o boleto bancário pode ser pago EM QUALQUER AGÊNCIA BANCÁRIA ou CASA LOTÉRICA. Boleto pode demorar até 72h para compensar (depende do banco). Não se preocupe, pois quando o sistema reconhecer o pagamento você recebe o e-mail com o login e a senha.',
    display: true,
  },
  {
    question: 'Posso pagar as mensalidades no boleto?',
    answer: 'No boleto, somente em única parcela à vista.',
    display: true,
  },
  {
    question: 'Quero tirar mais dúvidas, qual o contato?',
    answer: 'Você pode encaminhar um e-mail para o nosso suporte: contato@fisicaensinosuperior.com.br',
    display: true,
  },
];

export default faq;
