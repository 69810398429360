import Vue from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import AppHeader from '@/components/AppHeader.vue';
import AppHeaderSectionBg from '@/components/AppHeaderSectionBg.vue';
import SideMenu from '@/components/SideMenu.vue';
import PlatformPublicSection from '@/components/sections/PlatformPublicSection.vue';
import PlatformVideoSection from '@/components/sections/PlatformVideoSection.vue';
import MotivationMessageSection from '@/components/sections/MotivationMessageSection.vue';
import PlatformBenefitsSection from '@/components/sections/PlatformBenefitsSection.vue';
import PlatformPlansSection from '@/components/sections/PlatformPlansSection.vue';
import MoneyBackSection from '@/components/sections/MoneyBackSection.vue';
import PlatformTestimonialSection from '@/components/sections/PlatformTestimonialSection.vue';
import PlatformTeacherSection from '@/components/sections/PlatformTeacherSection.vue';
import PlatformFaqSection from '@/components/sections/PlatformFaqSection.vue';
import GKathIcon from '@/assets/icons/GKathIcon.vue';
import AppFooter from '@/components/AppFooter.vue';
import CookieConsent from '@/components/CookieConsent.vue';
import VideoDialog from '@/components/VideoDialog.vue';
import RegistrationListDialog from '@/components/RegistrationListDialog.vue';

const registerComponents = (): void => {
  Vue.component('app-header', AppHeader);
  Vue.component('app-footer', AppFooter);
  Vue.component('app-header-section-bg', AppHeaderSectionBg);
  Vue.component('side-menu', SideMenu);
  Vue.component('font-awesome-icon', FontAwesomeIcon);
  Vue.component('platform-public-section', PlatformPublicSection);
  Vue.component('platform-video-section', PlatformVideoSection);
  Vue.component('motivation-message-section', MotivationMessageSection);
  Vue.component('platform-benefits-section', PlatformBenefitsSection);
  Vue.component('platform-plans-section', PlatformPlansSection);
  Vue.component('money-back-section', MoneyBackSection);
  Vue.component('platform-testimonial-section', PlatformTestimonialSection);
  Vue.component('platform-teacher-section', PlatformTeacherSection);
  Vue.component('platform-faq-section', PlatformFaqSection);
  Vue.component('video-dialog', VideoDialog);
  Vue.component('registration-list-dialog', RegistrationListDialog);
  Vue.component('cookie-consent', CookieConsent);
  Vue.component('gkath-icon', GKathIcon);
};

export default { registerComponents };
