












































import Vue from 'vue';
import { mapGetters } from 'vuex';
import AppFooter from './components/AppFooter.vue';
import AppHeader from './components/AppHeader.vue';

export default Vue.extend({
  name: 'App',
  computed: mapGetters(['appIsLoading']),
  components: { AppFooter, AppHeader },
});
