





























































import Vue from 'vue';
import testimonials from '@/metadata/Testimonials';

export default Vue.extend({
  name: 'PlatformTestimonialSection',
  data() {
    return {
      testimonials,
      showVideo: false,
      clickedTestimonial: 0,
    };
  },
  methods: {
    openVideo(clicked: number) {
      console.log('AQUIII');
      this.clickedTestimonial = clicked;
      this.showVideo = true;
    },
  },
});
