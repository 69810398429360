



























































import GKathIcon from '@/assets/icons/GKathIcon.vue';
import Vue from 'vue';

export default Vue.extend({
  components: { GKathIcon },
  name: 'AppFooter',
  props: {
    cnpj: String,
    facebook: String,
    youtube: String,
    instagram: String,
    twitter: String,
    pinterest: String,
    telegram: String,
    contactMail: String,
  },
  computed: {
    displaySocialMedias() {
      const socialMedias = [
        {
          icon: ['fab', 'facebook'],
          url: this.facebook,
        },
        {
          icon: ['fab', 'youtube'],
          url: this.youtube,
        },
        {
          icon: ['fab', 'instagram'],
          url: this.instagram,
        },
        {
          icon: ['fab', 'twitter'],
          url: this.twitter,
        },
        {
          icon: ['fab', 'pinterest'],
          url: this.pinterest,
        },
        {
          icon: ['fab', 'telegram'],
          url: this.telegram,
        },
      ];

      return (socialMedias).filter((item) => item.url !== undefined);
    },
    mailtoContact() {
      return `mailto:${this.contactMail}`;
    },
  },
});
