
































import Vue from 'vue';
import benefits from '@/metadata/Benefits';

export default Vue.extend({
  name: 'PlatformBenefitsSection',
  data() {
    return {
      benefits,
    };
  },
});
