














import Vue from 'vue';
import userTypes from '@/metadata/UserTypes';
import VideoDialog from '../VideoDialog.vue';

export default Vue.extend({
  components: { VideoDialog },
  name: 'PlatformVideoSection',
  props: {
    url: String,
  },
  data() {
    return {
      userTypes,
      dialogOpen: false,
    };
  },
  methods: {
    openDialog() {
      console.log('AQUI');
      this.dialogOpen = true;
    },
  },
});
