import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/terms-and-conditions',
    name: 'TermsAndConditions',
    component: () => import(/* webpackChunkName: "terms-and-conditions" */ '../views/TermsAndConditions.vue'),
  },
  {
    path: '/privacy-policy',
    name: 'TermsAndConditions',
    component: () => import(/* webpackChunkName: "terms-and-conditions" */ '../views/PrivacyPolicy.vue'),
  },
  {
    path: '/learning-plan',
    name: 'LearningPlan',
    component: () => import(/* webpackChunkName: "terms-and-conditions" */ '../views/LeaningPlan.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
