import ITestimonial from '@/types/Testimonial';

const testimonials: ITestimonial[] = [
  {
    name: 'Karolina Kimberly',
    image: '/img/testimonials/testimonial-1.webp',
    videoUrl: 'https://www.youtube.com/embed/YGt9z8UAgOM',
  },
  {
    name: 'Elisa Friggi',
    image: '/img/testimonials/testimonial-2.webp',
    videoUrl: 'https://www.youtube.com/embed/eNsGLLjK8lE',
  },
  {
    name: 'Matheus Farias',
    image: '/img/testimonials/testimonial-4.webp',
    videoUrl: 'https://www.youtube.com/embed/B0Tac1DBfiI',
  },
  {
    name: 'Jean Flávio',
    image: '/img/testimonials/testimonial-6.webp',
    videoUrl: 'https://www.youtube.com/embed/BXB9gD2Rx5c',
  },
  {
    name: 'Jessica Alves',
    image: '/img/testimonials/testimonial-3.webp',
    videoUrl: 'https://www.youtube.com/embed/Hj8GSR2cjRM',
  },
  {
    name: 'Gabriel Colaço Alves',
    image: '/img/testimonials/testimonial-5.webp',
    videoUrl: 'https://www.youtube.com/embed/dhrtClz0xlE',
  },
];

export default testimonials;
