import { IMenu } from '@/types/Menu';

const menus: IMenu[] = [
  {
    icon: 'home',
    label: 'header.menu.home',
    link: '/',
  },
  {
    icon: 'shopping-bag',
    label: 'header.menu.buy',
    link: '/#plans',
  },
  {
    icon: 'calendar-day',
    label: 'header.menu.study-plan',
    link: '/learning-plan',
  },
  {
    icon: 'user',
    label: 'header.menu.student',
    link: 'https://aln.fisicaensinosuperior.com.br',
  },
];

export default menus;
